'use client';
import Bugsnag from "@bugsnag/js";
import { useEffect } from 'react';
import {useTekstClient} from "hooks/useTekstClient";
import useGebruiker from "hooks/useGebruiker";

export default function Error({error,reset}) {
    const {ingelogdeGebruiker} = useGebruiker()
    const {tekst, isLoadingTekst} = useTekstClient()
    console.log("mijnmkg", error)

    useEffect(() => {
        // Optionally log the error to an error reporting service
        //console.error(error);
        // Bugsnag.notify(error)

        Bugsnag.notify(error, (event) => {
            event.addMetadata('error-page', {
                message: error.message,
                stack: error.stack,
            })
        })
    }, [error]);

    return (
        <main className="flex h-full flex-col items-center justify-center">
            <h2 className="text-center">{tekst("algemeen:errorpagina:header")}</h2>
            <p>{tekst("algemeen:errorpagina:tekst")}</p>
            <button
                className="mt-4 rounded-md bg-blue-500 px-4 py-2 text-sm text-white transition-colors hover:bg-blue-400"
                onClick={ () => reset() } >
                {tekst("algemeen:errorpagina:button")}
            </button>
        </main>
    );
}